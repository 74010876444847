
import { Component, Vue } from 'vue-property-decorator'
import SetPasswordContent from '@/components/SetPasswordContent/SetPasswordContent.vue'
import { namespace } from 'vuex-class'

const UserStore = namespace('user')

/**
 * "Passwort ändern"-View
 */
@Component({
  components: {
    SetPasswordContent
  }
})
export default class PasswordChange extends Vue {
  /**
   * ID des angemeldeten Benutzers.
   */
  @UserStore.Getter('id')
  public userId!: string
}
